"use client";

import { ReactNode } from "react";
import {
  Box,
  BoxProps,
  Divider,
  Modal,
  ModalProps,
  Paper,
  PaperProps,
  Stack,
  Typography
} from "@mui/material";

import { ResponsiveStyle } from "theme/theme";

import { CloseButton } from "../../component/button";

export interface MhcModalProps extends ModalProps {
  additionalHeaderContent?: ReactNode | null;
  title?: string;
  caption?: string | null;
  handleClose: () => void;
  useDivider?: boolean;
  width?: ResponsiveStyle;
  height?: ResponsiveStyle;
  headerStyle?: BoxProps["sx"];
  contentStyle?: BoxProps["sx"];
  modalPaperStyle?: PaperProps["sx"];
}

export const MhcModal: React.FC<MhcModalProps> = ({
  children,
  additionalHeaderContent = null,
  title,
  caption,
  handleClose,
  useDivider,
  width = "600px",
  headerStyle = {},
  contentStyle = {},
  modalPaperStyle = {},
  ...props
}) => {
  const style: BoxProps["sx"] = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width,
    maxWidth: "95vw",
    bgcolor: "background.paper",
    border: "0px transparent #000",
    py: {
      xs: 3,
      md: 4
    },
    ...modalPaperStyle
  };

  const id = props["aria-labelledby"];

  return (
    <Modal {...props} onClose={handleClose}>
      <Paper elevation={10} sx={style}>
        <Box
          component={"header"}
          id={`modal-${id ?? ""}-header`}
          sx={{
            display: "flex",
            position: "sticky",
            gap: 4,
            top: 0,
            pb: 2,
            px: { xs: 2, md: 4 },
            ...headerStyle
          }}
        >
          <Stack direction="row" justifyContent="space-between" alignItems="center" flexGrow={1}>
            <Stack gap={0.5}>
              {title && (
                <Typography id={id} variant="h3" component="h4" data-testid="modal-title">
                  {title}
                </Typography>
              )}
              {caption && (
                <Typography variant="caption" component="p" data-testid="modal-caption">
                  {caption}
                </Typography>
              )}
            </Stack>
            {additionalHeaderContent}
          </Stack>
          <CloseButton onClick={handleClose} sx={{ marginLeft: "auto" }} />
        </Box>
        {useDivider === true && <Divider sx={{ mx: { xs: 2, md: 4 } }} />}
        <Box
          sx={{
            maxHeight: "75vh",
            overflow: "auto",
            px: { xs: 2, md: 4 },
            pt: { xs: 2, md: 4 },
            ...contentStyle
          }}
        >
          {children}
        </Box>
      </Paper>
    </Modal>
  );
};
